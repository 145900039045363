/* Variables */
$dark-bg: #121212;
$wrapper-bg: #1e1e1e;
$commanders-table-bg: #1a1a1a;
$players-table-bg: #573535;
$tournaments-table-bg: #8b3232;
$text-light: #f5f5f5;
$border-dark: #333;
$table-hover: #3e3e3e;
$scrollbar-thumb: #4a4a4a;
$scrollbar-thumb-hover: #6a6a6a;
$scrollbar-thumb-active: #8a8a8a;

/* Centered Container */
.centered-container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 70px); // Adjust the 70px to your navbar height
  background-color: $dark-bg;
}

/* Wrapper */
.wrapper {
  width: 70%;
  max-width: 70%;
  text-align: center;
  background-color: $wrapper-bg;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  font-size: 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    font-size: 3.4vw;
    padding: 2vw;
  }
}

/* Table Wrapper */
.cedh-table-wrapper {
  display: block;
  width: auto;
  max-width: 100%;
  max-height: 60vh;
  overflow: auto;
  margin: 0 auto;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb $wrapper-bg;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $wrapper-bg;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 4px;

    &:hover {
      background: $scrollbar-thumb-hover;
    }
    &:active {
      background: $scrollbar-thumb-active;
    }
  }
}



/* Table */
.cedh-table {
  display: table;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 auto;
  color: $text-light;
  font-size: 60%;
  white-space: nowrap;
  border: 1px solid $border-dark;

  th, td {
    border: 1px solid $border-dark;
    padding: 8px;
    text-align: left;
  }

  th {
    position: sticky;
    top: 0;
    background-color: darken($wrapper-bg, 5%);
    z-index: 2;
    cursor: pointer;
    user-select: none;

    &.asc::after {
      content: " 🔼";
    }

    &.desc::after {
      content: " 🔽";
    }
  }
  
  .tournaments-cedh-table tr:nth-child(even) {
    background-color: darken($commanders-table-bg, 5%);
  }

  .tournaments-cedh-table tr:nth-child(odd) {
    background-color: $commanders-table-bg;
  }

  .players-cedh-table tr:nth-child(even) {
    background-color: darken($commanders-table-bg, 5%);
  }

  .players-cedh-table tr:nth-child(odd) {
    background-color: $commanders-table-bg;
  }
  
  .commanders-cedh-table tr:nth-child(even) {
    background-color: darken($commanders-table-bg, 5%);
  }

  .commanders-cedh-table tr:nth-child(odd) {
    background-color: $commanders-table-bg;
  }

  tr:hover {
    background-color: $table-hover;
  }

  select {
    font-size: inherit;
    border: 2px solid $text-light;
    background-color: $wrapper-bg;
    color: $text-light;
    padding: 2px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  td div img {
    width: 1em;
    height: 1em;
  }
}

/* Mobile Hidden */
@media (max-width: 1200px) {
  .mobile-hidden,
  .cedh-table th.mobile-hidden,
  .cedh-table td.mobile-hidden {
    display: none;
  }

  .cedh-table th, td {
    padding: 4px;
  }
}

/* Filters */
.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  color: $text-light;
  justify-content: center;
  align-items: center;

  .date-range,
  .tournament-filters,
  .entries-filter {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.tournament-filters .reminder {
  font-size: 60%;
}

.react-datepicker__input-container input {
  width: 7rem;
  padding: 3px 6px;
  font-size: 0.85em;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f9f9f9;
  text-align: center;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    border-color: #888;
    background-color: #e9e9e9;
  }

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
}

.react-datepicker-popper {
  z-index: 5;
}

/* Filter Buttons */
.filter-button {
  padding: 5px 10px;
  color: $text-light;
  background-color: darken($wrapper-bg, 10%);
  border: 1px solid $border-dark;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.911);
  display: inline-block;

  &.active {
    color: #fff;

    &.bronze {
      box-shadow: 0 0 0.4rem 0.3rem rgb(146, 92, 29);
    }

    &.silver {
      box-shadow: 0 0 0.4rem 0.3rem rgb(192, 192, 192);
    }

    &.gold {
      box-shadow: 0 0 0.4rem 0.3rem rgb(231, 197, 0);
    }

    &.platinum {
      box-shadow: 0 0 0.4rem 0.3rem rgb(100, 166, 197);
    }
  }

  &:hover {
    background-color: $table-hover;
  }
}

.entries-filter input[type="number"] {
  width: 50px;
  text-align: center;
  padding: 3px;
  color: #353535;
  background-color: rgb(249, 249, 249);
  border: 1px solid #353535;
  border-radius: 3px;
}